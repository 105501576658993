import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);

        this.events = {
          click: {
            open: 'open',
            close: 'close',
            closeFiltres: 'closeFiltres',
            openFiltres: 'openFiltres',
          }
        };

        this.ajaxurl = '/wp/wp-admin/admin-ajax.php';
    }

    init() {
      if(this.getData('open')) {
        this.open();
        // let $cookie = false;
        // jQuery.ajax({
        //   url: this.ajaxurl,
        //   type: 'POST',
        //   data: {'action': 'check_alerte_cookie'},
        //   success: function( response ){
        //     if (!response) {
        //       const html = document.querySelector('html');
        //       setTimeout(function(){ html.classList.add('has-modalOpen'); }, 6000);
        //     }
        //   },
        // });
      }
    }

    open(e) {
      const target = e.currentTarget;
      const template = target.getAttribute('data-template');
      if(template) {
        jQuery.ajax({
          url: this.ajaxurl,
          type: 'POST',
          data: {'action': 'modal_get_template', 'template': template},
          success: function( response ){
            $('.c-modal').find('.c-modal_wrap').html(response.content);
            $('.c-modal').addClass('is-open');
          },
        });
      }else {
        const div = this.$('modal-wrap');
        const html = $(div).html();
        
        $('.c-modal').find('.c-modal_wrap').html(html);
        $('.c-modal').addClass('is-open');
  
        if(this.getData('cookie')) {
          const cookie = this.getData('cookie');
          $('.c-modal').attr('data-modal-cookie', cookie);
        }
      }

    }

    close() {
      $('.c-modal').removeClass('is-open');
      
      if(this.getData('cookie')) {
        const cookie = this.getData('cookie');
        jQuery.ajax({
          url: this.ajaxurl,
          type: 'POST',
          data: {'action': 'set_popup_cookie', 'cookie': cookie},
          success: function( response ){
            
          },
        });
      }
    }

    toggleModal() {
      $('.c-modal').toggleClass('is-open');
    }

    softOpen() {
      $('.c-modal').addClass('is-open');
    }

    closeFiltres() {
      $('.c-modal-filtres').removeClass('is-open');
    }

    openFiltres(e) {
      const el = e.currentTarget;
      $('.c-modal-filtres').addClass('is-open');
    }
}
