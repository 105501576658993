/**
 * External Dependencies
 */
import 'jquery';

import './locomotive/app';

$(() => {
  
  var targets = $('.c-glossaire');
  targets.each(function() {
    var target = $(this);
    var tooltip = target.find('.description');
    var parent = target.parent('div');

    if( parent.width() < tooltip.outerWidth() * 1.5 ) {
      tooltip.css( 'max-width', parent.width() / 2 );
    }else {
      tooltip.css( 'max-width', 340 );
    }

    var pos_left = target.offset().left + ( target.outerWidth() / 2 ) - ( tooltip.outerWidth() / 2 ),
        pos_top  = target.offset().top - tooltip.outerHeight() - 20;

    if( pos_left < 0 ) {
        pos_left = target.offset().left + target.outerWidth() / 2 - 20;
        tooltip.addClass( 'left' );
    }else {
      tooltip.removeClass( 'left' );
    }

    if( pos_left + tooltip.outerWidth() > parent.width() ) {
        pos_left = target.offset().left - tooltip.outerWidth() + target.outerWidth() / 2 + 20;
        tooltip.addClass( 'right' );
    }else {
      tooltip.removeClass( 'right' );
    }

    if( pos_top < 0 ) {
        var pos_top  = target.offset().top + target.outerHeight();
        tooltip.addClass( 'top' );
    }else {
      tooltip.removeClass( 'top' );
    }
  });
});
