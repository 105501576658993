import { module } from 'modujs';
import 'select2/dist/js/select2.min.js';
import 'select2/dist/js/i18n/fr.js';
import 'select2/dist/css/select2.min.css';

export default class extends module {
    constructor(m) {
        super(m);

        this.events = {
          focusin: {
            input: 'active',
          },
          focusout: {
            input: 'desactive',
          },
          change: {
            toggleChild: 'toggleChild',
          },
        }

        this.ajaxurl = '/wp/wp-admin/admin-ajax.php';
    }

    init() {
      let $this = this;
      let items = this.$('select2');
      let placeholder = 'Choisir';
      if(items) {
        items.forEach(element => { 
          if(element.getAttribute('data-placeholder')) {
            placeholder = element.getAttribute('data-placeholder');
          }
          $(element).select2({
            language: 'fr',
            placeholder: placeholder,
            allowClear: true,
            minimumInputLength: 3,
          });
          // $('#'+element.getAttribute('id')).on('change.select2', function() {
          //   $this.call('refresh', false, 'Filtres');
          // });
        });
      }
    }

    active(e) {
      const target = e.currentTarget;
      const item = this.parent('item', target);
      item.classList.add('is-active');
    }

    desactive(e) {
      const target = e.currentTarget;
      const item = this.parent('item', target);
      if(target.value == "") {
        item.classList.remove('is-active');
      }
    }

    toggleChild(e) {
      let el = e.currentTarget;
      let val = $(el).val();
      let child = $(el).attr('data-child');
      $(child).html('');
      let template = $(el).attr('data-template');
      
      jQuery.ajax({
        url: this.ajaxurl,
        type: 'POST',
        data: {'action': 'toggleChild', 'val': val, 'template': template},
        success: function (response) {
          $(child).html(response.content);
        },
      });
  }
}
